import "./styles.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { AmityUiKitProvider, AmityUiKitSocial } from "@amityco/ui-kit-open-source";
import { apiSetVisitorAccessKey } from "@amityco/ui-kit-open-source/src/core/utils/getApiCodeRequest"
import loading from './loading.json'
import Lottie from 'lottie-react';

import Login from "./Login";

// const apiKey = "b3bee90c39d9a5644831d84e5a0d1688d100ddebef3c6e78"; //dev
// const apiKey = "b0eeba593b8ea1634f618d14555c168884588de4ee603b29"; //us

export default function App() {

  const [user, setUser] = useState({ userId: "", displayName: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [myTheme, setMyTheme] = useState({
    palette: {
      primary: '#1e8db5',
      secondary: '#919596',
      highlight: '#1D1D1B',
      background: '#f7f7f8'
    }
  })
  

  useEffect(() => {
    if (user.userId.length > 0) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user])

  function is3rdPartyCookiesBlocked() {
    try {
      sessionStorage.setItem('test', 'test');
      sessionStorage.removeItem('test')
      return false
    }
    catch (e) {
      return true;
    }
  }

  function loadAmity(){
    return (
      <AmityUiKitProvider
          theme={myTheme}
          key={user.userId}
          apiKey={apiKey}
          apiEndpoint="https://api.eu.amity.co"
          userId={user.userId}
          displayName={user.displayName}
          platformUrl={platformUrl}
        >
          <AmityUiKitSocial />
      </AmityUiKitProvider>
    )
  }

  function showLoading(){
    return (
      <div id="loading-spinner-wrapper">
        <Lottie
            id="loading-spinner"
            animationData={loading}
            loop={true}
            autoplay={true}
        />
        <p>We are loading your social newsfeed...</p>
      </div>
    )
  }

  var apiKey = "";
  var platformUrl = "";
  
  console.log(window.location.hostname);

  if (window.location.hostname === "mysocial.ping-services-platform.com") {
    apiKey = "b0eeba593b8ea1634f618d14555c168884588de4ee603b29";
    platformUrl = "https://mypropertydemo.ping-services-platform.com/";
  }
  if (window.location.hostname === "greengatesocial.ping-services-platform.com") {
    apiKey = "b0eebc5a38d9f36d4e33df1d53011688845bdab7e8363d7f";
    platformUrl = "https://greengate.ping-services-platform.com/";
  }
  if (window.location.hostname === "tcrw.ping-services-platform.com") {
    apiKey = "b0efbb0b33d3a3341f66de4e510e178dd85f8cb7be666f29";
    platformUrl = "https://tcrwsohoapp.com/";
  }
  if (window.location.hostname === "homesbypicsocial.ping-services-platform.com") {
    apiKey = "b0e8ed583bdfa2364b31d81e0609158b840f8fb0e93c6f2b";
    platformUrl = "https://homesbypic.co.uk/";
  }
  if (window.location.hostname === "oneeightysocial.ping-services-platform.com") {
    apiKey = "b0e8ed5838dea5611d63d81f525d438b855f84b6bc3d6979";
    platformUrl = "https://oneeightystratfordapp.co.uk/";
  }
  if (window.location.hostname === "saffronsquaresocial.ping-services-platform.com") {
    apiKey = "b0e9ea5f33dca137493e8d4e560b178cd80a8bb0bf616b2e";
    platformUrl = "https://saffronsquare.co.uk/";
  }
  if(window.location.hostname === "myresidentapp.ping-services-platform.com"){
    apiKey = "b0e9ed0c3d8ea3634d63df480708428c8209d8b6e8336729";
    platformUrl = "https://myresident.app/";
  }
  if(window.location.hostname === "mychapterappsocial.ping-services-platform.com"){
    apiKey = "b0e9e0593a8aa63118308f1b075a428fd95bdde0bf316679";
    platformUrl = "https://mychapterapp.com/";
  }
  if(window.location.hostname === "mycanvasappsocial.ping-services-platform.com"){
    apiKey = "b0e9e0593a88a3374d308c15000c16818601d8e0eb613c2b";
    platformUrl = "https://mycanvasapp.com/";
  }
  if(window.location.hostname === "coppermakersquare.ping-services-platform.com"){
    apiKey = "b0e9ba086bdef3374f34854a040b1f8ad40c8feae831697d";
    // platformUrl = "https://copper-maker-square.ping-services-platform.com/";
    platformUrl = "https://mycoppermakersquareapp.com/"
  }
  if(window.location.hostname === "newcapitalquaysocial.ping-services-platform.com"){
    apiKey = "b0e9bb5a3adbf6604d368e1c040011ddd401dee7ec31687d";
    platformUrl = "https://newcapitalquayapp.com/";
  }
  if(window.location.hostname === "myskyresidencesocial.ping-services-platform.com"){
    apiKey = "b0e9bb5a3adbf9344430df4d570b16db82088fb1be35672b";
    platformUrl = "https://myskyresidence.co.uk/";
  }
  if(window.location.hostname === "myforlivingsocial.ping-services-platform.com"){
    apiKey = "b0e9bb583cdbf4664c64844d5b59118f83008eeabf336e29";
    platformUrl = "https://myforlivingapp.co.uk/";
  }
  if(window.location.hostname === "mydock5social.ping-services-platform.com"){
    apiKey = "b0e9bc5f6f8da6334e61d81f5a0d1781d75bdfe0e83c3c7d";
    platformUrl = "https://mydock5app.co.uk/";
  }
  if(window.location.hostname === "myallegrosocial.ping-services-platform.com"){
    apiKey = "b0e9bc523e8ea2604e34884a00011fda855f85eaef353b28";
    platformUrl = "https://myallegrolivingapp.co.uk/";
  }
  if(window.location.hostname === "my250cityroadapp.ping-services-platform.com"){
    apiKey = "b0e9bc0c3ddba16d1d3e8f4d560a138dd45ddfb7b23d6a2c";
    platformUrl = "https://my250cityroadapp.co.uk/";
  }
  if(window.location.hostname === "almaaldgatesocial.ping-services-platform.com"){
    apiKey = "b0e9bd5c388fa1604935dc4e510b428bd50a85e0b937672e";
    platformUrl = "https://alma-aldgate-app.co.uk/";
  }
  if(window.location.hostname === "myqueenstreetquartersocial.ping-services-platform.com"){
    apiKey = "b0e9be5b3fdbf6651861df485309148b820cd9e1ee346f2c";
    platformUrl = "https://myqueenstreetquarter.co.uk/";
  }

  if(window.location.hostname === "myclarendonqtrsocial.ping-services-platform.com"){
    apiKey = "b0e9be583ad8a1311e638818015d1eded30f8ce6ee356c2e";
    platformUrl = "https://myclarendonqtr.co.uk/";
  }
  if(window.location.hostname === "mykingschelseasocial.ping-services-platform.com"){
    apiKey = "b0eae85969d8f66c1d61dc485409448f860d8fe3bf663d2c";
    platformUrl = "https://mykingschelseaapp.com/";
  }
  if(window.location.hostname === "hestiaresidentappsocial.ping-services-platform.com"){
    apiKey = "b0eaea583a8da5674e3fdb49540f168b835ddeb4b3616f7e";
    platformUrl = "https://hestiaresidentapp.com/";
  }
  if(window.location.hostname === "dandaralivingsocial.ping-services-platform.com"){
    apiKey = "b0eaea5e68dcf5624c36de1b5a0816da855d89e6e9356924";
    platformUrl = "https://residents.dandaraliving.com/";
  }
  if(window.location.hostname === "millersquaysocial.ping-services-platform.com"){
    apiKey = "b0eaed093bd3a46c1d30891b040c158d850f85eabb3c6c24";
    platformUrl = "https://millersquayapp.co.uk/";
  }
  if(window.location.hostname === "gemresidentsocial.ping-services-platform.com"){
    apiKey = "b0eaed0f38d9a6311a3edf1d530f1e88d05889e1b3616d29";
    platformUrl = "https://gemresidentapp.co.uk/";
  }
  if(window.location.hostname === "essexstreetsocial.ping-services-platform.com"){
    apiKey = "b0eabe5b3388a2614f62df19010d4280d701dde3b9673d2f";
    platformUrl = "https://mysouthcentralapp.com/";
  }
  if(window.location.hostname === "myroselawnsocial.ping-services-platform.com"){
    apiKey = "b0eabe586fdca1614f62df19010d428bd40ddee4e93d6c2b";
    platformUrl = "https://myroselawnapp.com/";
  }
  if(window.location.hostname === "social.pingplatformdemo.com"){
    apiKey = "b0eabe0f6b8af5634434dc49550a44d9d1088de1e8666d29";
    platformUrl = "https://pingplatformdemo.com/";
  }





  useEffect(() => {
    if(is3rdPartyCookiesBlocked()) {
      alert("Your browser is blocking sessionStorage. Website functionality may not work correctly, please enable it in your browser settings cookie policy")
    }
    const fetch = async () => {
      const visitorAccessKey = await apiSetVisitorAccessKey(platformUrl)
      axios.post(platformUrl + "getVisitor", {},
          {
            headers: {
              'token': visitorAccessKey,
              'Content-Type': 'application/json'
            }
          }
      ).then((response) => {
        var email = response.data.visitor["email"];
        var name = response.data.visitor["name"];
        var id = response.data.visitor["id"].toString()

        axios.post(platformUrl + "api/resident-app-colours/building",
            { resident_id: id },
            {
              headers: {
                'token': visitorAccessKey,
                'Content-Type': 'application/json'
              }
            }
        ).then((response) => {
          if (response.status === 200) {
            setMyTheme({
              palette: {
                primary: response.data.social.primary_color ?? myTheme.palette.primary,
                secondary: response.data.social.secondary_color ?? myTheme.palette.secondary,
                highlight: response.data.social.background_background_color ?? myTheme.palette.highlight,
                background: response.data.social.background_background_color ?? myTheme.palette.background,
              }
            })
          }
        })

        axios.post(platformUrl + "admin/api/autojoin", {},
            {
              headers: {
                'token': visitorAccessKey,
                'Content-Type': 'application/json'
              }
            }
        ).then((response) => {
            if (response.data.success) {
            setUser({userId: email, displayName: name});
            setIsLoading(false);
          }
        })
      })
    }
    fetch()

  }, [])

  return (
      <div className="App">
        {isLoading ? (
            showLoading()
        ) : (
            loadAmity()
        )}
      </div>
  );
}